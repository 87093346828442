export const RA_PAGE_TYPE = {
  INTRO: 'intros',
  SURVEY: 'surveys',
  RESULT: 'normalResults',
  DNA: 'dnaResults',
};

export const RA_PROGRESS_STATUS = {
  READY: '미참여', // 미참여
  ONGOING: '진행중', // 진행중
  DONE: '완료', // 완료
};
