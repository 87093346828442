import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, List, notification, Row, Spin } from 'antd';
import LoadingOverlay from 'react-loading-overlay';
import { parse } from 'qs';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import WindowHeader from '../../components/windowHeader';
import AntTinymceInput from '../../components/antTinymceInput';
import { useFetch } from '../../../hooks/useRequest';
import {
  createPspTerm,
  deletePspTermVersion,
  fetchPspTerm,
  updatePspTerm,
} from '../../../services/pspService';
import ElementLoading from '../../components/elementLoading';

const PspTermWindow = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [windowId, setWindowId] = useState('');
  const [id, setid] = useState(null);
  const [versionId, setVersionId] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [form] = Form.useForm();
  const initialState = {
    title: '',
    description: '',
  };

  const { data: PspTermData, call: fetchPspTermData } = useFetch(
    null,
    fetchPspTerm,
    id,
    versionId,
  );

  const { search } = useLocation();

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setid(params.id);
    }
    if (params.versionId) {
      setVersionId(params.versionId);
    }
    setIsLoading(false);
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId]);

  useEffect(() => {
    if (id && versionId) {
      fetchPspTermData();
    }
  }, [fetchPspTermData, id, versionId]);

  useEffect(() => {
    if (PspTermData) {
      form.setFieldsValue({
        ...PspTermData,
      });
      setDescription(PspTermData.description);
    }
  }, [PspTermData, form]);

  const handleSubmit = async (values) => {
    const { isNok: isNokStr, new: isNew } = parse(search, {
      ignoreQueryPrefix: true,
    });
    const isNok = isNokStr === 'true';
    const REQ_BODY = {
      ...values,
    };

    if (
      window.confirm(
        `저장하시겠습니까?${
          isNew
            ? ''
            : '\n약관 변경으로 인해 기존 약관에 동의한 유저들은 재동의가 필요합니다.'
        }`,
      )
    ) {
      if (id) {
        // patch
        const result = await updatePspTerm(id, REQ_BODY);
        window?.opener?.postMessage(
          `agreement ${result.id} ${JSON.stringify(result)}`,
          '/',
        );
      } else {
        // post
        REQ_BODY.type = 'patient';
        const result = await createPspTerm(REQ_BODY);
        window?.opener?.postMessage(
          `agreement ${result.id} ${JSON.stringify(result)}`,
          '/',
        );
      }
      alert('저장되었습니다.');
      window.close();
    }
  };

  if (isLoading) return <ElementLoading type="운영 > PSP > 약관 서류" />;
  return (
    <>
      <WindowHeader title="운영 > PSP > 약관 서류" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={(error) => {
              notification.error({
                key: 'onFailed',
                message: '저장에 실패했습니다. 필수 항목을 확인해주세요.',
              });
            }}
            initialValues={initialState}
          >
            <Form.Item
              label="약관명"
              name="title"
              rules={[
                {
                  required: true,
                  message: '약관명을 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="약관 내용"
              name="description"
              rules={[
                {
                  required: true,
                  message: '약관 내용을 확인해주세요.',
                },
              ]}
              className="treatmentContent"
            >
              <LoadingOverlay
                active={imageLoading}
                spinner={<Spin />}
                text={<p>이미지를 업로드 하는 중</p>}
              >
                <AntTinymceInput
                  content={description}
                  setContent={(value) => {
                    form.setFieldsValue({
                      description: value,
                    });
                    setDescription(value);
                  }}
                  setImageLoading={setImageLoading}
                />
              </LoadingOverlay>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default PspTermWindow;
