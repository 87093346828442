export const PSP_MEDICATION_STATUS = {
  scheduled: '투약 예정',
  completed: '투약',
  missing: '미투약',
};

export const PSP_REQUEST_STEP = {
  terms: '약관 동의 페이지',
  contents: '콘텐츠 확인 페이지',
  ra: 'RA 확인 페이지',
  config: '투약 설정 페이지',
  medication: '투약 관리 페이지',
};

export const PSP_REQUEST_STATUS = {
  REQUESTING: '신청 중',
  COMPLETED: '신청 완료',
};
