import {
  AndroidOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  NotificationOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import 'antd/dist/antd.css';
import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PAGE_URL from '../assets/pageUrl';
import * as permissions from '../util/permission';

import { paginationCreators } from '../store/reducers/pagination.reducer';

const SideBar = ({ link }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { permission, tabPage } = useSelector((state) => {
    return {
      permission: state.userReducer.permission?.data,
      tabPage: state?.paginationReducer?.tabPage,
    };
  }, shallowEqual);

  const getData = useCallback(() => {
    dispatch(
      paginationCreators.setValue({
        tabPage: [...tabPage],
      }),
    );
  }, [dispatch]);

  useEffect(getData, [getData]);

  const setTab = new Set(tabPage);
  const [openKeys, setOpenKeys] = useState([...setTab]);

  const { Sider } = Layout;

  const permissionGuard = (items) => {
    return items.filter((item) => permission.includes(item.permission));
  };

  const sideElementAccount = [
    {
      label: '유저',
      key: 'user',
      permission: permissions.ACCOUNT_USER,
    },
    {
      label: '탈퇴',
      key: 'userWithdrawal',
      permission: permissions.WITHDRAWAL_USER,
    },
    {
      label: '관리자 설정',
      key: 'admin',
      permission: permissions.ACCOUNT_ADMIN,
    },
  ];

  const sideElementData = [
    {
      label: 'PAP',
      key: PAGE_URL.PAP,
      permission: permissions.PAP,
    },
    {
      label: 'PSP',
      key: PAGE_URL.PSP,
      permission: permissions.PAP,
    },
    {
      label: 'RA',
      key: PAGE_URL.RAISING_AWARENESS,
      permission: permissions.RAISING_AWARENESS,
    },
    {
      label: '등록_RA 유전자 검사',
      key: PAGE_URL.RAISING_AWARENESS_REGISTER_REQUEST,
      permission: permissions.RAISING_AWARENESS,
    },
    {
      label: '등록_유전자 검사',
      key: PAGE_URL.MUTATION_DNA_REPORT_REGISTER_REQUEST,
      permission: permissions.DNA_REPORT,
    },
    {
      label: '등록_병원 검사',
      key: PAGE_URL.HOSPITALEXAMINATION_RECORDS_REQUEST,
      permission: permissions.DNA_REPORT,
    },
    {
      label: '등록_질환 인증',
      key: PAGE_URL.DISEASETYPECERTIFICATION_REQUEST,
      permission: permissions.DNA_REPORT,
    },
    {
      label: '카이안과 연구 신청',
      key: PAGE_URL.GENE_RESEARCH,
      permission: permissions.GENE_RESEARCH,
    },
    {
      label: '변이 유전자',
      key: PAGE_URL.MUTATION_DNA,
      permission: permissions.DNA_REPORT,
    },
    {
      label: '유전자 검사 리포트',
      key: PAGE_URL.MUTATION_DNA_REPORT,
      permission: permissions.DNA_REPORT,
    },
  ];

  const sideElementContent = [
    {
      label: '레어노트 콘텐츠',
      key: PAGE_URL.CONTENTS,
      permission: permissions.CONTENT_TREATMENT_NEWS,
    },
    {
      label: '글로벌 뉴스',
      key: PAGE_URL.RSS_FEED,
      permission: permissions.CONTENT_RSS_FEED,
    },
    {
      label: '질환 정보',
      key: PAGE_URL.DISEASEINFO,
      permission: permissions.CONTENT_TREATMENT_NEWS,
    },
    {
      label: '임상시험 현황',
      key: PAGE_URL.CLINICAL_TRIAL,
      permission: permissions.ADMIN_LOGIN,
    },
    {
      label: '환자 모임 정보',
      key: 'patientGroup',
      permission: permissions.ACCOUNT_PATIENT_GROUP,
    },
    {
      label: '공지/소식',
      key: PAGE_URL.NOTICE,
      permission: permissions.MANAGE_NOTICE,
    },
    {
      label: '미디어 모니터링',
      key: PAGE_URL.MEDIA_MONITOR_CONFIG,
      permission: permissions.MEDIA_MONITOR,
    },
  ];

  const sideElementSubContent = [
    {
      label: '용어 사전',
      key: PAGE_URL.DICTIONARY,
      permission: permissions.CONTENT_TERMS,
    },
    { label: '유전자', key: 'dna', permission: permissions.CONTENT_DNA },
    {
      label: '병원 검사',
      key: PAGE_URL.HOSPITAL_RECORD,
      permission: permissions.CONTENT_HOSPITAL_EXAMINATION,
    },
    {
      label: '의료진 프로필',
      key: PAGE_URL.DOCTORPROFILE,
      permission: permissions.DOCTORPROFILE,
    },
    {
      label: '주제별 태그',
      key: PAGE_URL.TOPIC_TAG,
      permission: permissions.TOPIC_TAG,
    },
    {
      label: '번역 대치어',
      key: PAGE_URL.TRANSLATE_WORD,
      permission: permissions.TRANSLATE_WORD,
    },
  ];

  const sideElementOperation = [
    {
      label: '질환',
      key: PAGE_URL.DISEASE_TYPE,
      permission: permissions.CONTENT_DISEASE,
    },
    {
      label: '앱 진입 모달',
      key: PAGE_URL.ENTRY_MODAL,
      permission: permissions.ENTRY_MODAL,
    },
    {
      label: '연구 소개',
      key: PAGE_URL.RESEARCH,
      permission: permissions.GENE_RESEARCH,
    },
    {
      label: '설문',
      key: PAGE_URL.HEALTH_PROFILE,
      permission: permissions.CONTENT_HEALTH_PROFILE,
    },
    {
      label: '주기적 설문',
      key: PAGE_URL.HEALTH_PROFILE_GROUP,
      permission: permissions.CONTENT_HEALTH_PROFILE,
    },
    {
      label: '콘텐츠 알림/푸시',
      key: PAGE_URL.NOTIFICATION,
      permission: permissions.MANAGE_NOTIFICATION,
    },
    {
      label: '메세지 알림/푸시',
      key: PAGE_URL.INDIVIDUAL_NOTIFICATION,
      permission: permissions.MANAGE_INDIVIDUAL_NOTIFICATION,
    },
  ];

  const sideElementDashboard = [
    {
      label: '전체',
      key: PAGE_URL.DASHBOARD,
      permission: permissions.DASHBOARD,
    },
    {
      label: '데이터 내역',
      key: PAGE_URL.DASHBOARD_DATA,
      permission: permissions.ACCOUNT_USER,
    },
  ];

  const sideElementDevelop = [
    {
      label: '강제업데이트',
      key: PAGE_URL.SUPPORT_VERSION,
      permission: permissions.SUPPORT_VERSION,
    },
    {
      label: '리모트 컨피그',
      key: PAGE_URL.REMOTE_CONFIG,
      permission: permissions.MANAGE_REMOTE_CONFIG,
    },
    {
      label: '개인정보 IP',
      key: PAGE_URL.IPALLOW,
      permission: permissions.IP_ALLOW,
    },
  ];

  const onOpenChange = (items) => {
    setOpenKeys(items);
    dispatch(
      paginationCreators.setValue({
        tabPage: items,
      }),
    );
  };

  const handleClick = (e) => {
    history.push(`/${e.key}`);
  };

  const MenuItems = [
    {
      label: '대시보드',
      key: 'tabDashboard',
      icon: <DashboardOutlined />,
      children: permissionGuard(sideElementDashboard),
    },
    {
      label: '계정',
      key: 'tabAccount',
      icon: <UserOutlined />,
      children: permissionGuard(sideElementAccount),
    },
    {
      label: '데이터',
      key: 'tabData',
      icon: <DatabaseOutlined />,
      children: permissionGuard(sideElementData),
    },
    {
      label: '콘텐츠',
      key: 'tabContent',
      icon: <FileTextOutlined />,
      children: permissionGuard(sideElementContent),
    },
    {
      label: '콘텐츠 보조 요소',
      key: 'tabSubContent',
      icon: <FileTextOutlined />,
      children: permissionGuard(sideElementSubContent),
    },
    {
      label: '운영',
      key: 'tabOperation',
      icon: <NotificationOutlined />,
      children: permissionGuard(sideElementOperation),
    },
    {
      label: '개발',
      key: 'tabDevelop',
      icon: <AndroidOutlined />,
      children: permissionGuard(sideElementDevelop),
    },
  ].filter((item) => item.children.length > 0);

  return (
    <Sider className="siderStyle">
      <Menu
        onClick={handleClick}
        defaultSelectedKeys={[link]}
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        items={MenuItems}
      />
    </Sider>
  );
};

export default SideBar;
