import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Select,
} from 'antd';
import moment from 'moment';
import { parse } from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useFetch } from '../../../hooks/useRequest';
import useWindow from '../../../hooks/useWindow';
import {
  fetchMediaMonitorConfigById,
  fetchMediaMonitorConfigNewsById,
  updateMediaMonitorConfigNews,
} from '../../../services/mediaMonitorService';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import WindowHeader from '../../components/windowHeader';

const MediaMonitorNewsWindow = () => {
  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [configId, setConfigId] = useState(null);
  const [newsId, setNewsId] = useState(null);
  const { destroyWindowById } = useWindow();

  const dispatch = useDispatch();
  const { diseaseTypeData } = useSelector((state) => {
    const diseaseTypes = state.diseaseTypeReducer.diseaseTypes?.data;
    return {
      diseaseTypeData: diseaseTypes,
    };
  });

  const getData = useCallback(() => {
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const { data: config, call: getConfig } = useFetch(
    null,
    fetchMediaMonitorConfigById,
    configId,
  );

  const { data: news, call: getNews } = useFetch(
    null,
    fetchMediaMonitorConfigNewsById,
    configId,
    newsId,
  );

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });

    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setNewsId(params.id);
    }
    if (params.configId) {
      setConfigId(params.configId);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId]);

  useEffect(() => {
    if (configId) {
      getConfig();
    }
    if (configId && newsId) {
      getNews();
    }
  }, [configId, getConfig, newsId, getNews]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (news && form && diseaseTypeData) {
      form.setFieldsValue({
        ...news,
        publishedDate: moment(news.publishedDate),
      });
    }
  }, [diseaseTypeData, form, news]);

  const handleSubmit = useCallback(
    (values) => {
      const requestBody = {
        ...values,
        publishedDate: undefined,
      };

      updateMediaMonitorConfigNews(configId, newsId, requestBody)
        .then(() => {
          alert('저장되었습니다.');
          window.close();
        })
        .catch(() => {
          notification.error({
            key: 'onFailed',
            message: '저장에 실패했습니다. 필수 항목을 확인해주세요.',
          });
        });
    },
    [configId, newsId],
  );

  return (
    <>
      <WindowHeader title="데이터 확인 / 수정" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={(error) => {
              notification.error({
                key: 'onFailed',
                message: '저장에 실패했습니다. 필수 항목을 확인해주세요.',
              });
            }}
          >
            <Form.Item label="국내/해외" className="mediaMonitorListHeader">
              <Input
                defaultValue={news?.lang === 'ko' ? '국내' : '해외'}
                disabled
              />
            </Form.Item>
            <Form.Item
              label="기사 제목"
              name="title"
              className="mediaMonitorListHeader"
              rules={[{ required: true, message: '기사 제목을 입력해주세요.' }]}
            >
              <Input placeholder="기사 제목을 입력해주세요." />
            </Form.Item>
            <Form.Item
              label="기사 발행일"
              name="publishedDate"
              className="mediaMonitorListHeader"
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label="대상 질환"
              name="diseaseTypeId"
              className="mediaMonitorListHeader"
              rules={[{ required: true, message: '대상 질환을 선택해주세요.' }]}
            >
              <Select
                style={{ width: '100%' }}
                placeholder="질환을 선택해주세요."
              >
                {config?.diseaseTypeIds
                  ?.map(
                    (diseaseTypeId) =>
                      diseaseTypeData?.find(
                        ({ id }) => id === diseaseTypeId,
                      ) ?? { id: diseaseTypeId, krName: diseaseTypeId },
                  )
                  .map((diseaseType) => (
                    <Select.Option
                      key={diseaseType?.krName}
                      id={diseaseType?.krName}
                      value={diseaseType?.id}
                    >
                      {diseaseType.krName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="발행 미디어"
              name="siteName"
              className="mediaMonitorListHeader"
              rules={[
                { required: true, message: '발행 미디어를 입력해주세요.' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="작성자"
              name="author"
              className="mediaMonitorListHeader"
              rules={[{ required: true, message: '작성자를 입력해주세요.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="설명 / GPT 요약문"
              name="description"
              className="mediaMonitorListHeader"
              rules={[{ required: true, message: '설명문을 입력해주세요.' }]}
            >
              <Input.TextArea autoSize={{ minRows: 5 }} />
            </Form.Item>
            <Form.Item
              label="원본 URL"
              name="url"
              className="mediaMonitorListHeader"
              rules={[{ required: true, message: 'URL을 입력해주세요.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                danger
                htmlType="button"
                style={{ width: 100, marginLeft: 8 }}
                onClick={() => window.close()}
              >
                취소
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default MediaMonitorNewsWindow;
