import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  notification,
  Radio,
  Row,
  Select,
  Typography,
  Card,
} from 'antd';
import AntTinymceInput from './antTinymceInput';
import MoveAppUrl from './moveAppUrl';
import React, { useCallback, useEffect, useState } from 'react';
import { MinusCircleOutlined } from '@ant-design/icons';
import {
  fetchRaResultPage,
  patchRaResultPage,
  postRaResultPage,
} from '../../services/raisingAwarenessService';
import { CONFLICT, NO_MATCHING_DATA_FOUND } from '../util/utils';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { treatmentNewsCreators } from '../../store/reducers/treatmentNews.reducer';
import { diseaseTypeCreators } from '../../store/reducers/diseaseType.reducer';
import { noticeCreators } from '../../store/reducers/notice.reducer';
import { dnaCreators } from '../../store/reducers/dna.reducer';
import { rssFeedCreators } from '../../store/reducers/rssFeed.reducer';
import { papProjectCreators } from '../../store/reducers/papProject.reducer';
import { raProgramCreators } from '../../store/reducers/raProgram.reducer';

const RaResultForm = ({
  raProjectId,
  pageId,
  variables,
  hasAnsweredUser,
  isApp,
}) => {
  const dispatch = useDispatch();
  const { treatmentNews, notices, dnas, rssFeed, pap, ra } = useSelector(
    (state) => {
      return {
        treatmentNews: state.treatmentNewsReducer.treatmentNews.data?.map(
          (treatment) => ({
            ...treatment,
            key: treatment?.id,
          }),
        ),
        notices: state.noticeReducer.notices.data,
        dnas: state.dnaReducer.dna?.data,
        rssFeed: state.rssFeedReducer?.rssFeed?.data,
        pap: state.papProjectReducer?.papProject?.data?.data,
        ra: state.raProgramReducer?.raProgram?.data,
      };
    },
    shallowEqual,
  );

  const [content, setContent] = useState('');
  const [hasCondition, setHasCondition] = useState(false);
  const [form] = Form.useForm();
  const isEditLimited = isApp || hasAnsweredUser;

  const getData = useCallback(async () => {
    if (!raProjectId || !pageId) return;
    const res = await fetchRaResultPage(raProjectId, pageId);
    form.setFieldsValue(res);
    setContent(res.content);
    setHasCondition(res.conditionGroups?.length > 0);
  }, [raProjectId, pageId]);

  useEffect(() => {
    getData();
    dispatch(
      treatmentNewsCreators.fetchAllTreatmentNews.request({
        params: '',
      }),
    );
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    dispatch(noticeCreators.fetchNotices.request());
    dispatch(dnaCreators.fetchAllDnas.request());
    dispatch(rssFeedCreators.fetchAllRssFeeds.request());
    dispatch(papProjectCreators.fetchAllPapProjects.request());
    dispatch(raProgramCreators.fetchAllRaPrograms.request());
  }, []);

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onFinish = async (values) => {
    if (!window.confirm('저장하시겠습니까?')) return;
    const isPatch = !!pageId;

    const data = {
      dnaTestReportResult: null,
      conditions: null,
      ...values,
      content,
      type: 'NORMAL_RESULT',
    };

    if (!data.cta.title || data.cta.title?.length === 0) {
      data.cta.title = null;
    }

    if (data.conditionGroups?.length > 0) {
      data.conditionGroups = data.conditionGroups.map((group, index) => ({
        ...group,
        logicalOperator: index === 0 ? null : group.logicalOperator,
      }));
    }

    try {
      if (isPatch) {
        delete data.conditions; // NOTE: 기존 로직과의 호환을 위해 있는 conditions 삭제 null로 보낼시 초기화 됨
        await patchRaResultPage(raProjectId, pageId, data);
      } else {
        await postRaResultPage(raProjectId, data);
      }
      window.alert('저장되었습니다.');
      window.close();
    } catch (e) {
      let description = undefined;
      if (e.data) {
        if (e.data.status === CONFLICT) {
          description =
            'RA 프로그램이 진행중/완료 상태이거나 앱에 노출중이지 않은지 확인해주세요.';
        }
        if (e.data.status === NO_MATCHING_DATA_FOUND) {
          description = '존재하지 않는 질환 또는 페이지입니다.';
        }
      }
      notification.error({
        message: '저장에 실패했습니다.',
        description,
      });
    }
  };

  return (
    <Form
      name="result"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      onFinish={onFinish}
      form={form}
      initialValues={{
        title: '',
        subtitle: '',
        content: '',
        cta: {
          type: 'DONE',
        },
        dnaTestReportResult: null,
      }}
    >
      <Form.Item label="다중 결과 페이지">
        <Checkbox
          disabled={isEditLimited}
          checked={hasCondition}
          onChange={(e) => {
            setHasCondition(e.target.checked);
          }}
        >
          선택 시 본 결과 페이지는 설정된 조건을 만족했을 때 표시됩니다.
        </Checkbox>
      </Form.Item>
      <Form.Item noStyle>
        {hasCondition === true && (
          <Form.Item
            wrapperCol={{
              offset: 4,
              span: 20,
            }}
          >
            <Form.List name="conditionGroups">
              {(groupFields, { add: addGroup, remove: removeGroup }) => (
                <>
                  {groupFields.map((groupField, groupIndex) => (
                    <div key={groupField.key} style={{ marginBottom: 24 }}>
                      {groupIndex > 0 && (
                        <Form.Item
                          name={[groupIndex, 'logicalOperator']}
                          label="그룹 논리 연산자"
                        >
                          <Select
                            style={{ width: 100 }}
                            disabled={isEditLimited}
                          >
                            <Select.Option value="AND">AND</Select.Option>
                            <Select.Option value="OR">OR</Select.Option>
                          </Select>
                        </Form.Item>
                      )}

                      <Card
                        title={`변수 그룹 ${groupIndex + 1}`}
                        extra={
                          <Button
                            icon={<MinusCircleOutlined />}
                            onClick={() => removeGroup(groupIndex)}
                            type="text"
                            disabled={isEditLimited}
                          />
                        }
                      >
                        <Form.List name={[groupIndex, 'conditions']}>
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name }) => (
                                <Row
                                  key={key}
                                  style={{ gap: 8, marginBottom: 8 }}
                                >
                                  <Button
                                    icon={<MinusCircleOutlined />}
                                    onClick={() => remove(name)}
                                    type="text"
                                    disabled={isEditLimited}
                                  />

                                  <Form.Item
                                    name={[name, 'logicalOperator']}
                                    hidden={name === 0}
                                  >
                                    <Select
                                      style={{ width: 80 }}
                                      disabled={isEditLimited}
                                    >
                                      <Select.Option value="AND">
                                        AND
                                      </Select.Option>
                                      <Select.Option value="OR">
                                        OR
                                      </Select.Option>
                                    </Select>
                                  </Form.Item>

                                  <Form.Item
                                    key={`${key}-variable`}
                                    label="변수명"
                                    name={[name, 'variable']}
                                    rules={[
                                      {
                                        required: true,
                                        message: '변수명을 입력해주세요.',
                                      },
                                    ]}
                                  >
                                    <Select
                                      showSearch
                                      style={{ minWidth: 200 }}
                                      disabled={isEditLimited}
                                    >
                                      {variables.map((variable) => (
                                        <Select.Option
                                          key={variable}
                                          value={variable}
                                        >
                                          {variable}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    key={`${key}-value`}
                                    label="값"
                                    name={[name, 'value']}
                                    rules={[
                                      {
                                        required: true,
                                        message: '값을 입력해주세요.',
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      placeholder="숫자 입력"
                                      min={0}
                                      maxLength={10}
                                      style={{ width: '100%' }}
                                      disabled={isEditLimited}
                                    />
                                  </Form.Item>
                                  <Form.Item name={[name, 'operator']}>
                                    <Select
                                      style={{ width: '100%' }}
                                      disabled={isEditLimited}
                                    >
                                      <Select.Option value="GTE">
                                        이상
                                      </Select.Option>
                                      <Select.Option value="LTE">
                                        이하
                                      </Select.Option>
                                      <Select.Option value="GT">
                                        초과
                                      </Select.Option>
                                      <Select.Option value="LT">
                                        미만
                                      </Select.Option>
                                      <Select.Option value="EQ">
                                        일치
                                      </Select.Option>
                                    </Select>
                                  </Form.Item>
                                </Row>
                              ))}
                              <Form.Item>
                                <Button
                                  onClick={() =>
                                    add({
                                      operator: 'GTE',
                                      logicalOperator: 'AND',
                                    })
                                  }
                                  style={{ width: '100%' }}
                                  type="dashed"
                                  disabled={isEditLimited}
                                >
                                  변수 추가
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </Card>
                    </div>
                  ))}

                  <Form.Item>
                    <Button
                      onClick={() =>
                        addGroup({
                          conditions: [
                            { operator: 'GTE', logicalOperator: 'AND' },
                          ],
                        })
                      }
                      style={{ width: '100%' }}
                      type="dashed"
                      disabled={isEditLimited}
                    >
                      변수 그룹 추가
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            {/* <Form.List name="conditions">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <Row key={key} style={{ gap: 8 }}>
                      <Button
                        icon={<MinusCircleOutlined />}
                        onClick={() => remove(name)}
                        type="text"
                        disabled={isEditLimited}
                      />

                      <Form.Item
                        name={[name, 'logicalOperator']}
                        hidden={name === 0}
                      >
                        <Select style={{ width: 80 }} disabled={isEditLimited}>
                          <Select.Option value="AND">AND</Select.Option>
                          <Select.Option value="OR">OR</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        key={`${key}-variable`}
                        label="변수명"
                        name={[name, 'variable']}
                        rules={[
                          {
                            required: true,
                            message: '변수명을 입력해주세요.',
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ minWidth: 200 }}
                          disabled={isEditLimited}
                        >
                          {variables.map((variable) => (
                            <Select.Option key={variable} value={variable}>
                              {variable}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        key={`${key}-value`}
                        label="값"
                        name={[name, 'value']}
                        rules={[
                          {
                            required: true,
                            message: '값을 입력해주세요.',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="숫자 입력"
                          min={0}
                          maxLength={10}
                          style={{ width: '100%' }}
                          disabled={isEditLimited}
                        />
                      </Form.Item>
                      <Form.Item name={[name, 'operator']}>
                        <Select
                          style={{ width: '100%' }}
                          disabled={isEditLimited}
                        >
                          <Select.Option value="GTE">이상</Select.Option>
                          <Select.Option value="LTE">이하</Select.Option>
                          <Select.Option value="GT">초과</Select.Option>
                          <Select.Option value="LT">미만</Select.Option>
                          <Select.Option value="EQ">일치</Select.Option>
                        </Select>
                      </Form.Item>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      onClick={() =>
                        add({
                          operator: 'GTE',
                          logicalOperator: 'AND',
                        })
                      }
                      style={{ width: '100%' }}
                      type="dashed"
                      disabled={isEditLimited}
                    >
                      변수 추가
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List> */}
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item
        label="제목"
        name="title"
        rules={[
          {
            required: true,
            message: '제목을 입력해주세요.',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="부제목" name="subtitle">
        <Input />
      </Form.Item>
      <MoveAppUrl
        type="contents"
        treatmentNews={treatmentNews}
        notices={notices}
        dnas={dnas}
        rssFeed={rssFeed}
        pap={pap}
        ra={ra}
      />
      <Form.Item label="내용">
        <AntTinymceInput content={content} setContent={setContent} />
        <Input.TextArea rows={10} value={content} readOnly />
      </Form.Item>
      <Form.Item
        label="CTA 연결"
        name={['cta', 'type']}
        rules={[
          {
            required: true,
            message: 'CTA 타입을 선택해주세요.',
          },
        ]}
      >
        <Radio.Group disabled={isEditLimited}>
          <Radio value="DONE">설문 완료</Radio>
          <Radio value="DNA_TEST_REPORT_RESULT">유전자 검사 결과 등록</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.CTALink !== currentValues.CTALink
        }
      >
        {({ getFieldValue }) => (
          <>
            {getFieldValue(['cta', 'type']) === 'DNA_TEST_REPORT_RESULT' && (
              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 20,
                }}
                style={{ marginTop: -24 }}
              >
                <Typography.Text type="success">
                  본 소개 페이지의 CTA는 유전자 검사 결과 등록 페이지로
                  연결됩니다.
                </Typography.Text>
              </Form.Item>
            )}
          </>
        )}
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 4,
          span: 20,
        }}
      >
        <Button type="primary" htmlType="submit" style={{ width: 100 }}>
          저장
        </Button>
        <Button
          htmlType="button"
          style={{ width: 100, marginLeft: 8 }}
          onClick={onReset}
        >
          취소
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RaResultForm;
