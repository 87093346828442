import {
  Col,
  Divider,
  Empty,
  Form,
  Input,
  Menu,
  Row,
  Select,
  Skeleton,
  Typography,
  Space,
} from 'antd';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { fetchPspUserRequest } from '../../../services/pspService';
import { PSP_REQUEST_STATUS } from '../../../util/pspConstants';
import { RA_PROGRESS_STATUS } from '../../../util/raConstants';
import { FORBIDDEN, NO_MATCHING_DATA_FOUND } from '../../../services/utils';

const UserPspRequest = ({ userId, setIsPapPermitted }) => {
  const [pspProjects, setPspProjects] = useState([]);
  const [papLoading, setPapLoading] = useState(true);
  const [selectedPspProjectId, setSelectedPspProjectId] = useState('');
  const [selectedPapRequestId, setSelectedPapRequestId] = useState('');
  const [currentPspProject, setCurrentPspProject] = useState(null);
  const [patientNumber, setPatientNumber] = useState('');

  // 콘텐츠 확인 여부로 변경할걸
  // const fetchHistories = useCallback(async () => {
  //   if (selectedPapRequestId?.length === 0) return;
  //   const result = await fetchPapUserRequestHistories(selectedPapRequestId);
  //   setHistoryData(result);
  // }, [selectedPapRequestId]);

  const fetchPapRequests = useCallback(async () => {
    try {
      const result = await fetchPspUserRequest(userId);
      setPspProjects(result);
      setSelectedPspProjectId(result[0]?.id ?? '');
      setCurrentPspProject(result[0]);
      setPapLoading(false);
    } catch (e) {
      if (e.status === 200 && e.data.status === FORBIDDEN) {
        setIsPapPermitted(false);
      } else {
        if (e.status === 200 && e.data.status === NO_MATCHING_DATA_FOUND) {
          alert('유효하지 않은 요청입니다.');
          history.goBack();
          return;
        }
        throw e;
      }
    }
  }, [history, setIsPapPermitted, userId]);

  useEffect(() => {
    const project = pspProjects.find((p) => p.id === selectedPspProjectId);
    setPatientNumber(project?.patientNumber);
    setCurrentPspProject(project);
    if (!currentPspProject || selectedPspProjectId === '') return;
  }, [currentPspProject, pspProjects, selectedPspProjectId]);

  useEffect(() => {
    fetchPapRequests();
  }, []);

  return (
    <Row wrap={false}>
      {papLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Col style={{ maxWidth: 160 }}>
            <Menu
              mode="inline"
              items={pspProjects.map((p) => ({
                label: p.pspTitle,
                key: p.id,
              }))}
              selectedKeys={selectedPspProjectId}
              onClick={(e) => setSelectedPspProjectId(e?.key)}
              style={{
                height: '100%',
              }}
            />
          </Col>
          <Col flex={1} style={{ padding: '8px 16px' }}>
            {currentPspProject ? (
              <>
                <Form labelCol={{ span: 8 }}>
                  <Form.Item label="환자 번호">
                    <Input readOnly value={patientNumber} />
                  </Form.Item>
                  <Form.Item label="신청일시">
                    <Input
                      readOnly
                      value={moment(currentPspProject.requestDate).format(
                        'YYYY-MM-DD HH:mm',
                      )}
                    />
                  </Form.Item>
                  <Form.Item label="투약 관리 신청 상태">
                    <Input
                      readOnly
                      value={PSP_REQUEST_STATUS[currentPspProject.status]}
                    />
                  </Form.Item>
                </Form>
                <Divider />
                <Typography.Title level={5}>콘텐츠 확인 여부</Typography.Title>
                <Form labelCol={{ span: 8 }}>
                  {currentPspProject.contents
                    .sort((a, b) => new Date(a.value) - new Date(b.value))
                    .map((col) => {
                      return (
                        <Fragment key={col.key}>
                          <Form.Item label={col.title}>
                            <Input
                              readOnly
                              value={RA_PROGRESS_STATUS[col.value] ?? col.value}
                            />
                          </Form.Item>
                        </Fragment>
                      );
                    })}
                </Form>
              </>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
                신청한 PSP가 없습니다.
              </Empty>
            )}
          </Col>
        </>
      )}
    </Row>
  );
};

export default UserPspRequest;
