import React from 'react';

import UserWithHealthProfile from '../../containers/userWithHealthProfile';
import PAGE_URL from '../../../assets/pageUrl';

const PspAuditDetail = () => {
  return <UserWithHealthProfile type="pspProject" tabLink={PAGE_URL.PSP} />;
};

export default PspAuditDetail;
