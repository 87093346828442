import React from 'react';
import { parse } from 'qs';
import { useLocation, useHistory } from 'react-router-dom';
import { Layout, Button, Row, Col } from 'antd';
import 'antd/dist/antd.css';
import { Header, SideBar, TitleBreadcrumb } from '../../component';

import UserInfo from '../pages/user/userInfo';
import UserHealthProfile from '../pages/user/userHealthProfile';
import UserCounseling from '../pages/user/userCounseling';

const typeMap = {
  user: '유저',
  clinicalTrialCounseling: '상담',
  mutationDnaReportRegisterRequest: '등록_유전자 검사',
  hospitalExaminationRecordsRequest: '등록_병원 검사',
  diseaseTypeCertificationsRequest: '등록_질환 인증',
  geneResearch: '카이안과 연구 신청',
  papProject: 'PAP 심사',
  pspProject: 'PSP',
};

const UserWithHealthProfile = ({ type, tabLink }) => {
  const { Content } = Layout;
  const { state, search } = useLocation();
  const history = useHistory();
  const params = parse(search, {
    ignoreQueryPrefix: true,
  });
  const userId = state?.userId ?? params.userId;

  if (!userId) {
    alert('잘못된 접근입니다.');
    history.goBack();
  }

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab={tabLink} link={type} />
        <Layout className="right-layout-floud">
          <TitleBreadcrumb
            title="계정"
            subTitle={typeMap[type]}
            className="white-bg"
          />
          <Content className="site-layout-background userContentStyle">
            <Row span={24}>
              <Col span={12} style={{ paddingRight: 8 }}>
                <Content>
                  <Button type="primary" onClick={() => history.goBack()}>
                    목록 돌아가기
                  </Button>
                </Content>
                <Content style={{ marginTop: 16 }}>
                  <UserInfo userId={userId} />
                </Content>
                <Content style={{ marginTop: 16 }}>
                  <UserCounseling userId={userId} type={type} />
                </Content>
              </Col>
              <Col span={12} style={{ paddingLeft: 8 }}>
                <Content>
                  <UserHealthProfile userId={userId} type={type} />
                </Content>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default UserWithHealthProfile;
