import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router';
import { parse } from 'qs';
import { arrayMoveImmutable } from 'array-move';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Radio,
  Row,
  Select,
  // Spin,
  Switch,
  Table,
  Upload,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

import { useDispatch, useSelector } from 'react-redux';
// import LoadingOverlay from 'react-loading-overlay';
import ElementLoading from '../../components/elementLoading';
import {
  createPspProject,
  deletePspProject,
  fetchPspProject,
  updatePspProject,
  createPspRaLinks,
  updatePspRaLinks,
  deletePspRaLinks,
  updatePspContent,
  deletePspContent,
  deletePspTerm,
} from '../../../services/pspService';

import { useFetch } from '../../../hooks/useRequest';
import WindowHeader from '../../components/windowHeader';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { raProgramCreators } from '../../../store/reducers/raProgram.reducer';
import useWindow from '../../../hooks/useWindow';
import { NEW } from '../../util/utils';
import useUploadFile from '../../util/useUploadFile';

const PspProjectWindow = () => {
  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [projectId, setProjectId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRA, setSelectedRA] = useState(null);
  const { findWindowById, createWindow, destroyWindowById } = useWindow();
  const [termDeleted, setTermDeleted] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [thumbnailLoading, setThumbnailLoading] = useState(false);
  const [distinctFileInputEvent, setDistinctFileInputEvent] = useState(null);

  const dispatch = useDispatch();

  const {
    publicUrl: distinctPublicUrl,
    fileId: distinctFileId,
    done: distinctImageUploadDone,
    imageUpload: distinctImageUpload,
    initialize: distinctUploadInitialize,
  } = useUploadFile(distinctFileInputEvent, 'PSP_THUMBNAIL');

  const uploadThumbImg = async (e) => {
    if (e && e.file && e.file.status) {
      setDistinctFileInputEvent(e);
      distinctImageUpload(e);
      setThumbnailLoading(true);
    }
  };

  const uploadProps = {
    customRequest: () => {},
    onChange: uploadThumbImg,
    onPreview: () => {},
  };

  const beforeUpload = (file) => {
    console.log('🔍 beforeUpload - 파일 타입:', file.type, '크기:', file.size);
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      notification.error({
        message: '이미지 파일만 업로드 가능합니다.',
        description: '지원되는 파일 형식: JPG, PNG, SVG, GIF 등',
      });
      return false;
    }

    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      notification.error({
        message: '이미지 크기가 10MB를 초과했습니다.',
        description: '이미지 크기를 줄여 다시 시도해 주세요.',
      });
      return false;
    }

    console.log('🆗 파일 검증 통과:', file.name);
    return true;
  };

  const getData = useCallback(() => {
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    dispatch(raProgramCreators.fetchAllRaPrograms.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const { data: PspProjectData, call: fetchPspProjectData } = useFetch(
    null,
    fetchPspProject,
    projectId,
  );

  const { diseaseTypeData, raProgramData } = useSelector((state) => {
    const diseaseTypes = state.diseaseTypeReducer.diseaseTypes?.data;
    const raPrograms = state.raProgramReducer.raProgram.data;

    return {
      diseaseTypeData: diseaseTypes,
      raProgramData: raPrograms,
    };
  });

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const initialState = {
    title: '',
    alias: '',
    createdAt: dayjs(),
    diseaseTypeIds: [],
    medicationDisplayName: '투약',
    status: 'ready',
    btnLabel: '',
    description: '',
    shareLink: null,
    uriScheme: null,
    agreements: [],
    contents: [],
    raPrograms: [],
    raProgramLinks: [],
    companyName: '',
    thumbnail: null,
  };
  const [state, setState] = useReducer(stateReducer, initialState);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });

    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setProjectId(params.id);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId]);

  useEffect(() => {
    if (distinctImageUploadDone) {
      if (distinctPublicUrl) {
        console.log('✅ 이미지 업로드 성공:', distinctPublicUrl);
        notification.success({
          message: '이미지 업로드 성공',
          description: '썸네일이 정상적으로 등록되었습니다.',
        });
        setState({ thumbnail: { id: distinctFileId } });
        setThumbnailLoading(false);
      } else {
        console.warn('⚠️ distinctPublicUrl이 없음.');
      }
    }
  }, [distinctImageUploadDone, distinctPublicUrl, distinctFileId]);

  useEffect(() => {
    if (projectId) {
      fetchPspProjectData();
    }
  }, [fetchPspProjectData, projectId]);

  useEffect(() => {
    if (PspProjectData) {
      // console.log('PspProjectData:', PspProjectData);
      // raProgramLinkId를 매핑하여 raPrograms 배열에 추가
      const raProgramsWithLinks = PspProjectData.raPrograms.map((ra) => {
        const matchedLink = PspProjectData.raProgramLinks.find(
          (link) => link.raProgramId === ra.id,
        );
        return {
          ...ra,
          raProgramLinkId: matchedLink ? matchedLink.id : null, // 일치하는 링크가 없으면 null
        };
      });

      // 상태 업데이트
      setState({
        ...PspProjectData,
        createdAt: dayjs(PspProjectData.createdAt),
        raPrograms: raProgramsWithLinks,
        raProgramLinks: Array.isArray(PspProjectData.raProgramLinks)
          ? PspProjectData.raProgramLinks.map((link) => link.id)
          : [],
      });

      distinctUploadInitialize(PspProjectData?.thumbnail?.signedUrl);
      // form 필드값 설정
      form.setFieldsValue({
        ...PspProjectData,
        createdAt: dayjs(PspProjectData.createdAt),
        diseaseTypeIds: PspProjectData.diseaseTypes.map(
          (diseaseType) => diseaseType.id,
        ),
      });
    }
  }, [PspProjectData]);

  const [form] = Form.useForm();
  const { Option } = Select;

  const handleCreateAgreementsWindow = (type, agreement) => {
    if (findWindowById(agreement?.id)) {
      alert('이미 편집중인 약관입니다.');
      return;
    }
    createWindow({
      id: agreement?.id ?? `${NEW}${Date.now()}`,
      dataType: 'pspTerm',
      versionId: agreement?.id,
    });
  };

  const handleCreateContentsWindow = (type, content) => {
    if (findWindowById(content?.id)) {
      alert('이미 편집중인 약관입니다.');
      return;
    }
    createWindow({
      id: content?.id ?? `${NEW}${Date.now()}`,
      dataType: 'pspContents',
    });
  };

  const receiveMessage = useCallback(
    async (event) => {
      console.log('Message received:', event.data); // 메시지 데이터 확인

      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id, ...rest] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }

      if (command === 'agreement') {
        const result = JSON.parse(rest.join(' '));
        let isPatch = false;
        const newArray = state.agreements.map((agreement) => {
          if (agreement.id === result.id) {
            isPatch = true;
            return result;
          }
          return agreement;
        });
        if (!isPatch) {
          newArray.push(result);
        }
        console.log('New agreements array:', newArray); // 업데이트된 배열 확인

        setState({
          agreements: newArray,
        });
        form.setFieldsValue({
          agreements: newArray,
        });

        console.log('Form values after update:', form.getFieldsValue());

        if (projectId) {
          /**
           * NOTE(reo): 약관 수정 후 프로젝트 저장 없이 바로 알림을 보내면 수정된 약관에 대한 알림이 보내지지 않아 약관 부분만 선 저장
           * @see RARENOTE-3964
           */
          try {
            await updatePspProject(projectId, {
              agreements: newArray.map((agreement) => agreement.id),
            });
            notification.success({
              key: 'agreementSuccess',
              message: '약관 변경사항이 저장되었습니다.',
            });
          } catch (e) {
            notification.error({
              key: 'agreementFailed',
              message: '약관 변경사항 저장에 실패했습니다.',
            });
          }
        }
      }
      if (command === 'contents') {
        const result = JSON.parse(rest.join(' '));
        let isPatch = false;
        const newArray = state.contents.map((content) => {
          if (content.id === result.id) {
            isPatch = true;
            return result;
          }
          return content;
        });
        if (!isPatch) {
          newArray.push(result);
        }
        setState({
          contents: newArray,
        });
        form.setFieldsValue({
          contents: newArray,
        });
        if (projectId) {
          try {
            await updatePspProject(projectId, {
              contents: newArray.map((content) => content.id),
            });
            notification.success({
              key: 'contentSuccess',
              message: '콘텐츠가 저장되었습니다.',
            });
          } catch (e) {
            console.log(e);
            notification.error({
              key: 'contentFailed',
              message: '콘텐츠 변경사항 저장에 실패했습니다.',
            });
          }
        }
      }
    },
    [destroyWindowById, form, projectId, state.contents, state.agreements],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleDelete = async () => {
    try {
      const userConfirmed = window.confirm(
        '해당 PSP를 삭제하시겠습니까?\n기존에 등록된 약관 동의 항목, 콘텐츠 항목, RA항목이 모두 삭제됩니다.',
      );
      if (!userConfirmed) return;

      const projectRequest = await fetchPspProject(projectId);
      if (!projectRequest) {
        notification.error({
          key: 'deleteFailed',
          message: '삭제할 PSP 프로젝트를 찾을 수 없습니다.',
        });
        return;
      }

      // 삭제 요청
      await deletePspProject(projectId);
      notification.success({
        key: 'deleteSuccess',
        message: 'PSP 프로젝트가 성공적으로 삭제되었습니다.',
      });
      window.close();
    } catch (error) {
      console.error('PSP 삭제 실패:', error);
      notification.error({
        key: 'deleteError',
        message: 'PSP 삭제 중 오류가 발생했습니다.',
        description: error.message || '알 수 없는 오류',
      });
    }
  };

  const handleSubmit = async (values) => {
    const REQ_BODY = {
      ...values,
      createdAt: dayjs(values.createdAt).format('YYYY-MM-DD'), // dayjs로 포맷 변환
      agreements: state.agreements.map((agreement) => agreement.id),
      contents: state.contents.map((content) => content.id),
      raProgramLinks: state.raPrograms.map((ra) => ra.raProgramLinkId),
      thumbnail: state.thumbnail?.id,
    };
    delete REQ_BODY.shareLink;
    delete REQ_BODY.uriScheme;
    delete REQ_BODY.raProgram;

    console.log('🚀 REQ_BODY:', REQ_BODY);
    if (window.confirm('저장하시겠습니까?')) {
      setSubmitLoading(true);
      try {
        if (projectId) {
          await updatePspProject(projectId, REQ_BODY);
        } else {
          await createPspProject(REQ_BODY);
        }
        alert('저장되었습니다.');
        window.close();
      } catch (e) {
        console.log(e);
        notification.error({
          message: '저장에 실패했습니다.',
          description: e.message || '알 수 없는 오류가 발생했습니다.',
        });
      }
    }
    setSubmitLoading(false);
  };

  const handleAddRa = async () => {
    if (selectedRA) {
      const isAlreadyAdded = state.raPrograms.some(
        (ra) => ra.id === selectedRA,
      );
      if (!isAlreadyAdded) {
        const selectedProgram = raProgramData.find(
          (ra) => ra.id === selectedRA,
        );

        const raLinks = await createPspRaLinks({
          raProgramId: selectedProgram.id,
        });
        const processedProgram = {
          ...selectedProgram,
          isApp: false,
          raProgramLinkId: raLinks.id,
        };

        const newRAPrograms = [...state.raPrograms, processedProgram];

        setState({ raPrograms: newRAPrograms });
        form.setFieldsValue({ raPrograms: newRAPrograms });
      }
    } else {
      notification.warning({
        message: 'RA 프로그램을 선택해주세요.',
      });
    }
  };

  const handleUpdateRa = async (record, value) => {
    const raPrograms = state.raPrograms.map((raProgram) =>
      record.id === raProgram.id ? { ...raProgram, isApp: value } : raProgram,
    );
    console.log('🚀 record:', record);
    await updatePspRaLinks(record.raProgramLinkId, {
      isApp: value,
    });
    setState({ raPrograms });
    form.setFieldsValue({ raPrograms });
  };

  const handleDeleteRa = async (record) => {
    const filteredPrograms = state.raPrograms.filter(
      (ra) => ra.id !== record.id,
    );
    setState({ raPrograms: filteredPrograms });
    form.setFieldsValue({ raPrograms: filteredPrograms });
    await deletePspRaLinks(record.raProgramLinkId);
  };

  const handleUpdateContent = async (record, value) => {
    const contents = state.contents.map((content) =>
      record.id === content.id ? { ...content, isApp: value } : content,
    );
    await updatePspContent(record.id, { isApp: value });
    setState({ contents });
    form.setFieldValue('contents', contents);
  };

  const handleDeleteContent = async (id) => {
    if (window.confirm('콘텐츠를 삭제하시겠습니까?')) {
      const newContents = state.contents.filter((content) => content.id !== id);
      setState({ contents: newContents });
      form.setFieldsValue({ contents: newContents });
      try {
        await deletePspContent(id);
        notification.success({
          key: 'contentSuccess',
          message: '콘텐츠가 삭제되었습니다.',
        });
      } catch (e) {
        notification.error({
          key: 'contentFailed',
          message: '콘텐츠 삭제에 실패했습니다.',
        });
      }
    }
  };

  const handleDeleteTerm = async (id) => {
    if (window.confirm('삭제하시겠습니까?')) {
      setTermDeleted([...termDeleted, id]);
      const newAgreements = {
        agreements: state.agreements.filter((agreement) => agreement.id !== id),
      };
      setState(newAgreements);
      form.setFieldsValue(newAgreements);
      try {
        await deletePspTerm(id);
        notification.success({
          key: 'agreementSuccess',
          message: '약관에 삭제 되었습니다.',
        });
      } catch (e) {
        notification.error({
          key: 'agreementFailed',
          message: '약관 삭제 실패했습니다.',
        });
      }
    }
  };

  const moveContent = (id, direction) => {
    const index = state.contents.findIndex((content) => content.id === id);
    if (index === -1) return;

    const newIndex = direction === 'up' ? index - 1 : index + 1;
    if (newIndex < 0 || newIndex >= state.contents.length) return; // 범위를 벗어나지 않도록 체크

    const newContents = arrayMoveImmutable(state.contents, index, newIndex);
    setState({ contents: newContents });
    form.setFieldsValue({ contents: newContents });
  };

  const agreement_columns = [
    {
      title: '약관명',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            handleCreateAgreementsWindow('agreement', record);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: '등록일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => dayjs(text).format('YY.MM.DD HH:mm'),
    },
    {
      title: '최근 약관 변경일시',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => dayjs(text).format('YY.MM.DD HH:mm'),
    },
    {
      title: '약관 삭제',
      key: 'deleteAction',
      render: (text, record) => (
        <Button
          danger
          onClick={() => {
            handleDeleteTerm(record.id);
          }}
        >
          삭제
        </Button>
      ),
    },
  ];

  const contents_columns = [
    {
      title: '콘텐츠 제목',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            handleCreateContentsWindow('contents', record);
          }}
        >
          {text}
        </Button>
      ),
    },
    {
      title: '투약 정보 페이지 노출 여부',
      dataIndex: 'isApp',
      key: 'isApp',
      render: (value, record) => (
        <Switch
          checked={value}
          onChange={(checked) => handleUpdateContent(record, checked)}
        />
      ),
    },
    {
      title: '순서 변경',
      key: 'moveAction',
      render: (_, record) => {
        const index = state.contents.findIndex(
          (content) => content.id === record.id,
        );
        return (
          <div style={{ display: 'flex', gap: '8px' }}>
            <Button
              onClick={() => moveContent(record.id, 'up')}
              disabled={index === 0} // 첫 번째 항목이면 비활성화
            >
              ▲
            </Button>
            <Button
              onClick={() => moveContent(record.id, 'down')}
              disabled={index === state.contents.length - 1} // 마지막 항목이면 비활성화
            >
              ▼
            </Button>
          </div>
        );
      },
    },
    {
      title: '삭제',
      key: 'deleteAction',
      render: (text, record) => (
        <Button
          danger
          onClick={() => {
            handleDeleteContent(record.id);
          }}
        >
          삭제
        </Button>
      ),
    },
  ];

  const RA_columns = [
    {
      title: 'RA 제목',
      key: 'title',
      dataIndex: 'title',
      render: (text, _) => (
        <Button type="link" onClick={() => {}}>
          {text}
        </Button>
      ),
    },
    {
      title: '투약 정보 페이지 노출 여부',
      dataIndex: 'isApp',
      key: 'isApp',
      render: (value, record) => (
        <Switch
          checked={value}
          onChange={(checked) => handleUpdateRa(record, checked)}
        />
      ),
    },
    {
      title: '연결 제거',
      key: 'deleteAction',
      render: (value, record) => (
        <Button danger onClick={() => handleDeleteRa(record)}>
          삭제
        </Button>
      ),
    },
  ];

  if (isLoading) return <ElementLoading type="운영 > PSP" />;
  return (
    <>
      <WindowHeader title="운영 > PSP" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={(error) => {
              notification.error({
                key: 'onFailed',
                message: '저장에 실패했습니다. 필수 항목을 확인해주세요.',
              });
            }}
            initialValues={initialState}
          >
            <Form.Item
              label="PSP 명"
              name="title"
              tooltip="앱에 노출될 투약 관리 프로그램 이름을 설정해 주세요."
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="예) 싸이키커 투약 관리 프로그램" />
            </Form.Item>
            <Form.Item
              label="약제명"
              name="pharmaceuticalName"
              tooltip="앱 내에서 공통적으로 약제를 지칭할 이름을 입력해 주세요."
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="예) 싸이트로핀에이" />
            </Form.Item>
            <Form.Item
              label="투약/복약 선택"
              name="medicationDisplayName"
              tooltip="앱 내에서 투약 또는 복약을 선택하세요."
              rules={[
                {
                  required: true,
                  message: '투약 또는 복약을 선택해주세요.',
                },
              ]}
            >
              <Radio.Group>
                <Radio.Button value="투약">투약</Radio.Button>
                <Radio.Button value="복약">복약</Radio.Button>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label="제약사명"
              name="companyName"
              tooltip="투약 관리 대시보드에서 보여질 제약사명을 설정해 주세요."
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="예) 싸이젠 코리아" />
            </Form.Item>
            <Form.Item label="썸네일">
              <Upload
                {...uploadProps}
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                {distinctPublicUrl ? (
                  <img
                    src={distinctPublicUrl}
                    width="100%"
                    height="100%"
                    type="image/svg+xml"
                  />
                ) : (
                  <div>
                    {thumbnailLoading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  </div>
                )}
              </Upload>
            </Form.Item>
            <Form.Item
              label="등록일"
              name="createdAt"
              rules={[
                {
                  type: 'object',
                  required: true,
                  message: '등록일을 확인해주세요.',
                },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                value={dayjs(state.createdAt)} // moment -> dayjs로 변경
                onChange={(date) => {
                  setState({
                    createdAt: date ? date.format('YYYY-MM-DD') : null,
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label="질환 선택"
              name="diseaseTypeIds"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: '질환을 지정해주세요.',
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="질환 태그를 선택해주세요"
                filterOption={(input, option) => option.key.includes(input)}
              >
                {diseaseTypeData?.map((diseaseType) => (
                  <Option
                    key={diseaseType?.krName}
                    id={diseaseType?.krName}
                    value={diseaseType?.id}
                  >
                    {diseaseType.krName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="PSP 약칭"
              name="alias"
              tooltip="환자번호 자동 생성 시 부여할 PSP 약칭을 입력하세요."
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="예) kor" />
            </Form.Item>
            <Form.Item
              label="PSP 인증번호"
              name="authCode"
              tooltip="환자가 PSP 진입 시 입력할 인증 번호를 생성하세요. (숫자로만 구성)"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder="예) 1111" />
            </Form.Item>
            <Form.Item
              label="공유 링크"
              name="shareLink"
              tooltip="해당 PSP 신청 상세 페이지를 외부 앱에 공유하고 유입되게 하기 위한 링크입니다.  PSP 등록 내용 작성 후 첫 저장 시 링크가 자동 생성됩니다."
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item
              label="URI Scheme"
              name="uriScheme"
              tooltip="QR 코드 생성을 위한 URI Scheme 값입니다. ‘앱스플라이어 > Onelink > QR-to-app’ 생성 시 ‘Android and iOS fallback’ 영역에 해당 값을 입력해주세요.  PSP 등록 내용 작성 후 첫 저장 시 링크가 자동 생성됩니다."
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item label="환자 약관" name="agreements">
              <div>
                <Button
                  onClick={() => {
                    handleCreateAgreementsWindow('agreement', null);
                  }}
                >
                  약관 동의 항목 추가하기
                </Button>
              </div>

              <Table
                columns={agreement_columns}
                dataSource={state.agreements}
                size="small"
                pagination={false}
                bordered
                style={{ marginTop: 8 }}
                rowKey={(record) => record.id}
              />
            </Form.Item>
            <Form.Item label="콘텐츠 등록" name="contents">
              <div>
                <Button
                  onClick={() => {
                    handleCreateContentsWindow('contents', null);
                  }}
                >
                  콘텐츠 추가하기
                </Button>
              </div>
              <Table
                columns={contents_columns}
                dataSource={state.contents}
                size="small"
                pagination={false}
                bordered
                style={{ marginTop: 8 }}
                rowKey={(record) => record.id}
              />
            </Form.Item>
            <Form.Item label="RA 연결" name="raProgram">
              <Input.Group compact>
                <Select
                  allowClear
                  showSearch
                  style={{ width: 300 }}
                  placeholder="RA 제목을 입력하여 검색하세요"
                  filterOption={(input, option) => option.key.includes(input)}
                  onChange={(value) => setSelectedRA(value)}
                >
                  {raProgramData?.map((raProgram) => (
                    <Select.Option
                      key={raProgram?.title}
                      id={raProgram?.title}
                      value={raProgram?.id}
                    >
                      {raProgram.title}
                    </Select.Option>
                  ))}
                </Select>
                <Button
                  type="primary"
                  onClick={handleAddRa}
                  style={{ marginLeft: 8 }}
                >
                  추가
                </Button>
                <div
                  style={{
                    color: 'blue',
                    whiteSpace: 'pre-line',
                    marginTop: 8,
                  }}
                >
                  {`재무 컨설팅에 노출할 RA는 어드민 - 데이터 - RA에서 먼저 등록 후 이용할 수 있습니다.`.replace(
                    / - /g,
                    ' → ',
                  )}{' '}
                  <br />
                  <span style={{ fontWeight: 'bold' }}>
                    재무 컨설팅에 등록할 수 있는 RA는 유전자 검사와 관련된
                    기능이 포함되어있지 않아야 합니다. (연결시 앱에서 오류날 수
                    있음)
                  </span>
                  <br />
                  <span style={{ fontWeight: 'bold' }}>
                    재무 컨설팅에 등록할 RA는 필수로 전체 질환 선택 /관계에서는
                    환자, 보호자 모두 선택 후 생성해야 합니다.
                  </span>
                  <br />
                  재무 컬설팅에 연결되면 RA 리스트에서는 앱노출이 꺼지고, 켤 수
                  없습니다. RA 리스트에 다시 노출하고 싶으면 재무 컨설팅 연결을
                  제거하세요.
                  <br />
                </div>
                <Table
                  columns={RA_columns}
                  dataSource={state.raPrograms}
                  size="small"
                  pagination={false}
                  bordered
                  style={{ marginTop: 8 }}
                  rowKey={(record) => record.id}
                />
              </Input.Group>
            </Form.Item>

            {/* --------------------------------------------------------- */}

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: 100 }}
                loading={submitLoading}
              >
                저장
              </Button>
              {projectId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={handleDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default PspProjectWindow;
