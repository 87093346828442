import {
  Button,
  DatePicker,
  Divider,
  Input,
  Layout,
  Space,
  Table,
  Typography,
  Modal,
  message,
} from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { parse } from 'qs';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import PAGE_URL from '../../../assets/pageUrl';
import {
  fetchPspProject,
  fetchPspProjectRequests,
  deletePspProjectUserRequest,
} from '../../../services/pspService';
import { PSP_REQUEST_STATUS } from '../../../util/pspConstants';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import { NO_MATCHING_DATA_FOUND } from '../../../services/utils';

const PspAuditList = () => {
  const [pspProject, setPspProject] = useState(null);
  const [pspRequests, setPspRequests] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const confirmRef = useRef(() => {});

  const { pspRequestPagination } = useSelector((pagingState) => {
    return {
      pspRequestPagination: pagingState.paginationReducer.pspProjectPagination,
    };
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys),
  };

  const fetchList = useCallback(async () => {
    setLoading(true);
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    try {
      if (!params.id) throw new Error('invalid project id');
      const result = await fetchPspProjectRequests(params.id);
      console.log('Fetched PSP Requests:', result);
      const filteredResult = result.data.filter((r) => r.userId);
      dispatch(
        paginationCreators.setValue(
          (pspRequestPagination.total = filteredResult.length),
        ),
      );
      const project = await fetchPspProject(params.id);
      setPspProject(project);

      setPspRequests(filteredResult);
      setLoading(false);
    } catch (e) {
      if (e.data?.status === NO_MATCHING_DATA_FOUND || !params.id) {
        alert('존재하지 않는 PSP입니다.');
        history.push(`/${PAGE_URL.PSP}`);
      }
    }
  }, [dispatch, history, pspRequestPagination, search]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const handleMoveDetailPage = (record) => {
    history.push(`/${PAGE_URL.PSP_AUDIT_DETAIL}`, {
      userId: record?.userId ?? '',
      requestId: record?.id,
      type: 'psp',
    });
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    dispatch(
      paginationCreators.setValue(
        (pspRequestPagination.filter = filters),
        (pspRequestPagination.sorter = sorter),
        (pspRequestPagination.page = pagination.size),
        (pspRequestPagination.total = extra?.currentDataSource?.length),
      ),
    );
  };

  const handleReset = () => {
    if (selectedRowKeys.length === 0) {
      message.warning('삭제할 항목을 선택하세요.');
      return;
    }

    console.log('selectedRowKeys', selectedRowKeys);
    Modal.confirm({
      title: '선택한 답변 초기화',
      content: `선택한 ${selectedRowKeys.length}개의 답변을 초기화하시겠습니까?`,
      okText: '확인',
      cancelText: '취소',
      onOk: async () => {
        try {
          const params = parse(search, { ignoreQueryPrefix: true });
          if (!params.id) throw new Error('invalid project id');

          // DELETE API 호출
          await deletePspProjectUserRequest(params.id, {
            ids: selectedRowKeys,
          });

          // 삭제 성공 메시지
          message.success('선택한 답변이 초기화되었습니다.');

          // 데이터 다시 불러오기
          fetchList();

          // 선택된 항목 초기화
          setSelectedRowKeys([]);
        } catch (error) {
          console.log('error', error);
          message.error('선택한 답변 초기화 중 오류가 발생했습니다.');
        }
      },
    });
  };

  const getColumnSearchDateProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <DatePicker.RangePicker
            defaultValue={[
              selectedKeys?.[0]
                ? moment(selectedKeys?.[0], 'YYYY-MM-DD')
                : null,
              selectedKeys?.[1]
                ? moment(selectedKeys?.[1], 'YYYY-MM-DD')
                : null,
            ]}
            onChange={(e) => {
              if (e !== null) {
                if (
                  e?.[0].format('YYYY-MM-DD') === e?.[1].format('YYYY-MM-DD')
                ) {
                  setSelectedKeys([
                    e?.[0].format('YYYY-MM-DD'),
                    moment(e?.[1]).add(1, 'days').format('YYYY-MM-DD'),
                  ]);
                } else {
                  setSelectedKeys([
                    e?.[0].format('YYYY-MM-DD'),
                    e?.[1].format('YYYY-MM-DD'),
                  ]);
                }
              } else {
                setSelectedKeys([]);
              }
            }}
            format="YYYY-MM-DD"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
          </Space>
        </div>
      );
    },
    onFilter: (_, record) => {
      if (
        !pspRequestPagination.filter[dataIndex] ||
        pspRequestPagination.filter[dataIndex].length !== 2
      )
        return true;
      const [startStr, endStr] = pspRequestPagination.filter[dataIndex];
      const value = moment(record[dataIndex]);
      const start = moment(startStr);
      const end = moment(endStr).add(1, 'days');
      return value.isBetween(start, end);
    },
  });

  const columns = [
    {
      title: '계정',
      dataIndex: 'email',
      key: 'email',
      width: 200,
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            handleMoveDetailPage(record);
          }}
          style={{
            width: '100%',
            textAlign: 'left',
            whiteSpace: 'normal',
            wordBreak: 'break-all',
          }}
        >
          {text ?? record.socialUid}
        </Button>
      ),
      sorter: (a, b) =>
        (a.email || a.socialUid)?.localeCompare(b.email || b.socialUid),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      onFilter: (keyword, { email, name }) =>
        [email, name].some((value) => value?.includes(keyword)),
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
    },
    {
      title: '환자 번호',
      dataIndex: 'patientNumber',
      key: 'patientNumber',
      sorter: (a, b) => a.patientNumber.localeCompare(b.patientNumber),
    },
    {
      title: '신청일시',
      key: 'requestDate',
      dataIndex: 'requestDate',
      render: (value) =>
        value ? <div>{moment(value).format('YYYY.MM.DD HH:mm')}</div> : '-',
      sorter: (a, b) => moment(a.requestDate || 0).diff(b.requestDate || 0),
      ...getColumnSearchDateProps('requestDate'),
    },
    {
      title: '투약관리 신청 상태',
      key: 'status',
      dataIndex: 'status',
      render: (text, record) => (
        <div
          className="tagWrapper"
          key={record.id}
          style={{
            color: '#333',
            backgroundColor: '#fff',
          }}
        >
          {PSP_REQUEST_STATUS[text]}
        </div>
      ),
      sorter: (a, b) =>
        Object.keys(PSP_REQUEST_STATUS).indexOf(a.status) -
        Object.keys(PSP_REQUEST_STATUS).indexOf(b.status),
      filters: Object.keys(PSP_REQUEST_STATUS).map((key) => ({
        text: PSP_REQUEST_STATUS[key],
        value: key,
      })),
      onFilter: (value, record) => record.status === value,
    },
  ];

  const { Search } = Input;
  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabData" link={PAGE_URL.PSP} />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="운영"
            subTitle="PSP 심사 관리"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Typography.Title level={4}>{pspProject?.title}</Typography.Title>
            <Button
              type="link"
              href={`/${PAGE_URL.PSP}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/${PAGE_URL.PSP}`);
              }}
            >
              PSP 리스트로 돌아가기
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => {
                handleReset();
              }}
            >
              선택한 답변 초기화
            </Button>

            <Divider />
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
                dispatch(
                  paginationCreators.setValue(
                    (pspRequestPagination.filter.keyword =
                      value.length > 0 ? value : null),
                  ),
                );
              }}
              defaultValue={pspRequestPagination.filter.keyword}
            />
            <div className="searchResult">{`검색결과 ${pspRequestPagination.total}개`}</div>
            <Table
              loading={loading}
              pageUpdate={page}
              setPageUpdate={setPage}
              columns={columns}
              dataSource={pspRequests}
              size="small"
              bordered
              rowSelection={rowSelection}
              onChange={onTableChange}
              rowKey={(record) => record.id}
              pagination={{
                pageSize: 20,
                showSizeChanger: true,
              }}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default PspAuditList;
